import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/elements/Banner"
import Button from "../components/elements/Button"
import globalVariables from "../components/globalVariables"

const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  a {
    min-width: 250px;
  }
  a:first-child {
    margin-bottom: 1rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    a {
      min-width: auto;
    }
    a:first-child {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`

const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: { eq: "agence/join.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout location="/" crumbLabel="404">
      <SEO title="404 : Page introuvable | SLAP digital" />
      <Banner
        imageBanner={data.banner.childImageSharp.fluid}
        title="Page introuvable"
        description={`<p>
            Revenez vite vers notre page d'accueil, découvrez nos expertises, ou
            restez informé des dernières actualités du web en lisant les
            articles de notre blog.
          </p>`}
        imageHeight="480px"
        imageMobileHeight="580px"
        isCenter
      >
        <WrapperButton>
          <Button
            title="Retour à la page d'accueil"
            colors="primaryWithBg"
            to="/"
            as="link"
          />
          <Button
            title="Contactez-nous"
            colors="primaryWithBg"
            to="/contact/"
            as="link"
          />
        </WrapperButton>
      </Banner>
    </Layout>
  )
}

export default NotFoundPage
